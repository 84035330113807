import { useEffect, useState } from "react";
import { connect } from "react-redux";
import TextView from "../partials/Typography";
import DropDown from "../partials/DropDown";
import RoleSelection from "./partials/RoleSelection";
import Avatar from "./partials/Avatar";
import AvatarDropDown from "../partials/user_profile/AvatarDropDown";
import { getUserByToken } from "../../app/crud/person.crud";
import displayToast from "../partials/DisplayToast";
import { isEmpty } from "../../helpers/Functions";
import { setCurrentCapabilities } from "../../app/redux/slices/authSlice";
import { getCompanyCapabilities } from "../../helpers/Utils";

const MainNavbar = ({
	userDetails,
	userCapabilities,
	currentCapabilities,
	setCurrentCapabilities,
}) => {
	const [selectedCompany, setSelectedCompany] = useState(currentCapabilities?.company?.company_id);
	const [companyOptions, setCompanyOptions] = useState([]);
	const [userDetail, setUserDetail] = useState([]);
	const [update, setUpdate] = useState(true);
	const [loading, setLoading] = useState(false);

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const toggleDropdown = () => {
		setIsDropdownOpen((prev) => !prev);
	}

	const handleOutsideClick = (event) => {
		if (event.target.closest(".theme-header-profile") === null) {
			setIsDropdownOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleOutsideClick);

		return () => {
			document.removeEventListener("click", handleOutsideClick);
		}
	}, []);

	useEffect(() => {
		if (update) {
			setLoading(true);

			getUserByToken().then((response) => {
				if (response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					const parsedUserDetail = {
						...response.data.data[0],
						user_img: response.data.data[0].image_file,
						additional_info: JSON.parse(response.data.data[0].additional_info)
					};

					setUserDetail(parsedUserDetail)
				}

				setLoading(false);
				setUpdate(false);
			})
		}
	}, [update]);

	useEffect(() => {
		let companyData = [];

		if (!isEmpty(userCapabilities)) {
			userCapabilities.map((company) => {
				companyData.push({
					key: company.company_id,
					name: company.company_name
				});
			});
		}

		setCompanyOptions(companyData);
		setUserDetail(userDetails);
	}, []);

	const handleSelectCompany = (e) => {
		let selected = getCompanyCapabilities(userCapabilities, e.target.value);
		let updatedCapabilities = { ...currentCapabilities };
		updatedCapabilities.company = selected;

		let roleUpdate = selected.roles[0];

		updatedCapabilities.role = roleUpdate.role_id;
		updatedCapabilities.role_name = roleUpdate.role_name;
		updatedCapabilities.is_limited = roleUpdate.is_limited;

		setSelectedCompany(e.target.value);
		setCurrentCapabilities(updatedCapabilities);
	}

	return (
		<div className="theme-header">
			<div className="theme-header-breadcrumbs">
				<TextView customclasses="theme-header-breadcrumbs-left-text">
					Organization:
				</TextView>
				<DropDown
					itemclass="theme-header-breadcrumbs-selection"
					value={selectedCompany}
					options={companyOptions}
					onChange={handleSelectCompany}
					small="true"
				/>
			</div>
			<div className="theme-header-profile">
				{/* top right */}
				<RoleSelection />
				{
					userDetail && (
						<div className="theme-header-profile-container" onClick={toggleDropdown} >
							<div className="theme-header-profile-name font-weight-medium">
								{userDetail.firstname ? `Hi ${userDetail.firstname}!` : ""}
							</div>
							<Avatar
								userdetail={userDetail}
								setUpdate={setUpdate}
							/>
							{isDropdownOpen && (
								<>
									<AvatarDropDown
										userdetail={userDetail}
										setUpdate={setUpdate}
									/>
								</>
							)}
						</div>
					)
				}
			</div>
		</div>
	);
}


const mapStateToProps = (state) => {
	let data = {
		userDetails: state.auth.user_details,
		userCapabilities: state.auth.user_capabilities,
		currentCapabilities: state.auth.current_capabilities,
	}

	return data;
}

const mapDispatchToProps = {
	setCurrentCapabilities
}

export default connect(mapStateToProps, mapDispatchToProps)(MainNavbar);