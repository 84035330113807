import React, { createContext, useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useMatch } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

import MainNavbar from "./MainNavbar";
import LibraryDetailSidebar from "./LibraryDetailSidebar";
import MainSidebar from "./MainSidebar";
import { ToastContainer } from "react-toastify";
import { isEmpty } from "../../helpers/Functions";

import "../../assets/content.css";

const layoutContext = createContext();

export function useLayoutContext() {
	return useContext(layoutContext);
}

const Layout = () => {
	const [primaryOptions, setPrimaryOptions] = useState([]);
	const [navigationOptions, setNavigationOptions] = useState({});
	const [selectedPrimaryValue, setSelectedPrimaryValue] = useState(primaryOptions?.[0]?.value);
	const [uniqSelectedNavValue, setUniqSelectedNavValue] = useState(); // for catching title events
	const [selectedNavValue, setSelectedNavValue] = useState();
	const [selectedNavItemValue, setSelectedNavItemValue] = useState();
	
	const [isMainSidebarOpen, setIsMainSidebarOpen] = useState(true);
	const [isLibrarySidebarOpen, setIsLibrarySidebarOpen] = useState(false);

	const location = useLocation();
	const isInLibraryPage = useMatch('/dashboard/:type/:library_code');
	
	useEffect(() => {
		setIsMainSidebarOpen(!isInLibraryPage);
	}, [location]);

	const addPrimaryOption = (option) => {
		setPrimaryOptions((prevPrimaryOptions) => {
			let existingOption = prevPrimaryOptions.find((opt) => opt?.value === option?.value);

			if (isEmpty(existingOption)) {
				let newIconOptions = [...prevPrimaryOptions, option];
				newIconOptions.sort((a, b) => a?.position - b?.position);

				return newIconOptions;
			}

			return prevPrimaryOptions;
		});
	};

	const setNavigationOptionsFor = (value, navOption) => {
		let newNavOptions = { ...navigationOptions };
		newNavOptions[value] = navOption;

		setNavigationOptions(newNavOptions);
	}

	const getNavigationOptionsFor = (value) => {
		return [...navigationOptions?.[value] ?? []];
	}

	return (
		<layoutContext.Provider
			value={{
				isMainSidebarOpen,
				isLibrarySidebarOpen,
				selectedPrimaryValue,
				uniqSelectedNavValue,
				selectedNavValue,
				selectedNavItemValue,
				setIsMainSidebarOpen,
				setIsLibrarySidebarOpen,
				setSelectedPrimaryValue,
				setUniqSelectedNavValue,
				setSelectedNavValue,
				setSelectedNavItemValue,
				setNavigationOptionsFor,
				addPrimaryOption,
				getNavigationOptionsFor,
			}}
		>
			<ToastContainer />
			<div className="theme-left-container">
				<MainSidebar 
					isOpen={isMainSidebarOpen}
					setIsOpen={setIsMainSidebarOpen}
				/>
				<LibraryDetailSidebar 
					primaryOptions={primaryOptions}
					navigationOptions={navigationOptions}
					isOpen={isLibrarySidebarOpen}
					setIsOpen={setIsLibrarySidebarOpen}
					selectedPrimaryValue={selectedPrimaryValue}
					uniqSelectedNavValue={uniqSelectedNavValue}
					selectedNavValue={selectedNavValue}
					selectedNavItemValue={selectedNavItemValue}

					onPrimaryOptionClick={(primval) => {
						if (primval === selectedPrimaryValue && navigationOptions?.[primval]?.length > 0) {
							setIsLibrarySidebarOpen(!isLibrarySidebarOpen);
						}
						else {
							setSelectedPrimaryValue(primval);
						}
					}}

					onNavigationClick={(navval) => {
						setUniqSelectedNavValue(navval);
						setSelectedNavValue(null);
						setSelectedNavItemValue(null);
					}}

					onNavigationItemClick={(navval, navitemval) => {
						setSelectedNavValue(navval);
						setSelectedNavItemValue(navitemval);
						setUniqSelectedNavValue(null);
					}}
				/>
			</div>
			<div className="theme-right-container d-none d-md-flex">
				<div className="w-100">
					<MainNavbar />
					<div id="main-panel-container" className={`theme-content ${isInLibraryPage && 'library-detail-page'}`}>
						<Outlet />
					</div>
				</div>
			</div>
		</layoutContext.Provider>
	)
}

export default Layout;