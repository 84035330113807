const TextView = ({ fontsize = "primary", fontweight = "regular", fontcolor = null, display = "inline-block", ...props }) => {
	return (
		<div className={`d-${display} w-auto ${"font-size-" + fontsize} ${"font-weight-" + fontweight} ${fontcolor && `color-${fontcolor}`} ${props.customclasses}`} { ...props }>
			{ props.children }
		</div>
	)
}

export const HeaderTitle = ({ ...props }) => {
	let fontSize =  "font-size-primary";

	return (
		<TextView customclasses={`${props.customclasses} me-3 color-quinary ${fontSize}`}>
			{ props.children }
		</TextView>
	)
}

export const ElementTitle = ({ display = "block", ...props }) => {
	return (
		<TextView customclasses="mb-3" fontsize="large" display={display}>
			<b>{props.children}</b>
		</TextView>
	)
}

export default TextView;