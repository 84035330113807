import React from "react";
import {Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';

import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import ConnectedTvOutlinedIcon from '@mui/icons-material/ConnectedTvOutlined';
import PsychologyIcon from '@mui/icons-material/Psychology';
import BusinessIcon from '@mui/icons-material/Business';
import SettingsIcon from '@mui/icons-material/Settings';

import { checkIsActive } from "../helpers/RouterHelper";
import { isNotStudent } from "../../helpers/Utils";
import { DEFAULT_OLERN_WIDE_IMAGE } from "../../models/Constants";
import TextView from "../partials/Typography";
import { Box, List } from "@mui/material";
import { Image } from "react-bootstrap";
import ActionIconButton from "../partials/ActionIconButton";

const MainSidebar = ({
	isOpen = false,
	setIsOpen = () => {},
	currentCapabilities,
}) => {
	const location = useLocation();
	const isInAdminPanel = location.pathname.startsWith('/admin-panel');

	const updateThemeColor = (color) => {
		document.documentElement.style.setProperty("--theme-sidebar-highlight-color", "#3D85E6");
		document.documentElement.style.setProperty("--theme-sidebar-primary-color", "#2D73E1");
		document.documentElement.style.setProperty("--theme-sidebar-secondary-color", "#77BFFF");
		// setThemeColor(color);
	};

	const getMenuIsActive = (location, urls) => {
		if (Array.isArray(urls)) {
			for (let url of urls) {
				if (checkIsActive(location, url)) {
					return "active";
				}
			}
		}
		else {
			return checkIsActive(location, urls) ? "active" : "";
		}

		return "";
	}

	const dashboardUrlByRole = () => {
		if (isNotStudent(currentCapabilities.role)) {
			if (currentCapabilities?.is_limited) {
				return "/dashboard/course-sales";
			}

			return "/dashboard/admin-dashboard";
		}
		else {
			return "/dashboard/student-dashboard";
		}
	}

	const SIDEBAR_OPTIONS = [
		{
			title: '',
			line_break: true,
			hidden: isInAdminPanel,
			items: [
				{
					text: 'Dashboard',
					icon: (<BusinessIcon className="theme-sub-nav-icon" />),
					url: dashboardUrlByRole(),
					hidden: false,
				},
			]
		},
		{
			title: '',
			line_break: isNotStudent(currentCapabilities.role),
			hidden: isInAdminPanel,
			items: [
				{
					text: 'Online Courses',
					icon: (<LocalLibraryOutlinedIcon className="theme-sub-nav-icon" />),
					url: ['/dashboard/trainings', '/dashboard'],
					hidden: false,
				},
				{
					text: 'In-Person Trainings',
					icon: (<PersonPinOutlinedIcon className="theme-sub-nav-icon" />),
					url: '/dashboard/in-person-trainings',
					hidden: false,
				},
				{
					text: 'Webinars',
					icon: (<ConnectedTvOutlinedIcon className="theme-sub-nav-icon" />),
					url: '/dashboard/webinars',
					hidden: false,
				},
				{
					text: 'Intelliguide',
					icon: (<PsychologyIcon className="theme-sub-nav-icon" />),
					url: '/dashboard/intelliguide',
					hidden: false,
				},
			]
		},
		{
			title: '/dashboard/settings',
			line_break: false,
			hidden: !isNotStudent(currentCapabilities.role),
			items: [
				{
					text: 'Companies',
					icon: (<BusinessIcon className="theme-sub-nav-icon" />),
					url: '/admin-panel/companies',
					hidden: !isInAdminPanel,
				},
				{
					text: 'Settings',
					icon: (<SettingsIcon className="theme-sub-nav-icon" />),
					url: isInAdminPanel ? '/admin-panel/settings' : '/dashboard/settings',
					hidden: false,
				},
			]
		},
	];

	return (
		<div className={`main-sidebar ${!isOpen && 'close'}`}>
			<div className="main-sidebar-wide-image">
				<Image src={DEFAULT_OLERN_WIDE_IMAGE} />
			</div>
			<div className="main-sidebar-container">
				{SIDEBAR_OPTIONS.map((optionCluster) => (
					!optionCluster?.hidden && (
						<>
							<List className="main-sidebar-container-items">
								{optionCluster.items.map((item) => (
									!item.hidden && (
										<Link to={Array.isArray(item.url) ? item.url?.[0] : item.url} className={`lrnx-sidebar-wide-options-item ${getMenuIsActive(location, item.url)}`}>
											{item.icon}
											<TextView fontsize="secondary" fontweight="regular" customclasses="lrnx-sidebar-wide-options-item-text">{item.text}</TextView>
										</Link>
									)
								))}
							</List>
							{optionCluster?.line_break && <Box className="main-sidebar-divider" />}
						</>
					)
				))}
			</div>
			{/* <ActionIconButton
				customclasses="lrnx-sidebar-collapsible-header-collapse"
				label={"collapse"}
				onClick={() => {
					setIsOpen(!isOpen);
				}}
			/> */}
		</div>
	);
}


const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities
	}

	return data;
}

export default connect(mapStateToProps)(MainSidebar);