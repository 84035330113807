import React, {useState, Suspense, lazy, useEffect, useRef} from "react"
import { Image } from "react-bootstrap";
import TextView from "../theme/partials/Typography";
import { DEFAULT_OLERN_TRANSPARENT_LOGO } from "../models/Constants";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { IconButton, ButtonGroup } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Navigate, useNavigate, useParams } from "react-router-dom";
import displayToast from "../theme/partials/DisplayToast";
import { checkWebinarStudent } from "./crud/library.crud";
import { formatTimeToAmPm, isEmpty } from "../helpers/Functions";
import SplashScreen from "./SplashScreen";

import io from "socket.io-client";

const WaitingArea = ({
	...props
}) => {
	const { library_code, access_code } = useParams();
	const [webinarSettings, setWebinarSettings] = useState({});
	const navigate = useNavigate();
	const audioRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(true);
	const [isMuted, setIsMuted] = useState(false);

	const socket = useRef(null);
	
	useEffect(() => {
		if(!isEmpty(webinarSettings)){
			console.log("Socket URL:", process.env.REACT_APP_WEBSOCKET);

			socket.current = io(process.env.REACT_APP_WEBSOCKET, {
				withCredentials: true,
				transports: ["websocket", "polling"],
			});

			socket.current.on("connect", () => {
				console.log("Socket connected:", socket.current.id);
				joinRoom(library_code, access_code);
			});

			socket.current.on("disconnect", (reason) => {
				console.log("Socket disconnected. Reason:", reason);
			});

			socket.current.on("joinError", (data) => {
				displayToast("error", data.message);
				navigate('/error?error_code=join_room_error', { replace: true });
			});

			socket.current.on("sendToZoomMeetings", (data) => {
				if (data && data.url) {
					window.location.href = data.url;
				} else {
					console.error("No URL provided in data.");
				}
			});

			return () => {
				socket.current.disconnect();
			};
		}
	}, [webinarSettings])

	const joinRoom = (code, webinar_code) => {
		if (socket.current && socket.current.connected) {
			socket.current.emit("joinRoom", { "code" : code, "id" : webinar_code });
		}
		else {
			console.warn("Socket is not connected yet.");
		}
	};

	useEffect(() => {
		if(!isEmpty(audioRef.current)) {
			audioRef.current?.play();
		}
	}, [audioRef]);

	const handlePlayPause = () => {
		if (isPlaying) {
		  audioRef.current.pause();
		} else {
		  audioRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};
	
	  const handleMuteToggle = () => {
		audioRef.current.muted = !isMuted;
		setIsMuted(!isMuted);
	};

	useEffect(() => {
		checkWebinarStudent(library_code, access_code).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
				navigate('/error/', { replace: true });
			}
			else {
				let webSet = response.data.data[0]
				webSet.event_settings = JSON.parse(webSet.event_settings);
				setWebinarSettings(webSet)
			}
		})
	}, [])

	return (
		isEmpty(webinarSettings) ? (
			<SplashScreen />
		) : (
			<div 
				className="qa-wide-container p-3 w-100"
				style={{
					backgroundImage: !isEmpty(webinarSettings.event_settings.event_lobby_bg)  
						? process.env.REACT_APP_S3_BUCKET + "/event_uploads/images/event_lobby_bg/" + webinarSettings.event_settings.event_lobby_bg 
						: `url(${process.env.REACT_APP_S3_FILES}/resource_uploads/webinar_background/template_1.jpg)`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					height: '100vh'
				}}
			>
				<div className="w-100" style={{position: "relative"}}>
					<div style={{
						position: "relative",
						display: "inline-block",
						width: "100%",
						float: "left",
						zIndex: "1"
					}}>
						<div className="d-block text-center">
							<TextView 
								className="color-white font-size-large mb-2" 
								style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 1)' }}
							>
								This event starts in
							</TextView>
							<TextView className="color-white font-size-xl mt-2">
								This event is starting soon.
							</TextView>
						</div>
					</div>	
				</div>

				<div 
					className="w-100 d-none d-sm-none d-md-block d-lg-block" 
					style={{
						backgroundImage: "linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,0.6))",
						padding: "5vh 0 1vh",
						position: "absolute",
						bottom: 0,
					}}
				>
					<div className='d-flex w-100 justify-content-start align-items-end' style={{ height: '100%' }}>
						<div className="d-block p-5">
							<Image
								className={`d-flex mb-3`}
								style={{
									backgroundColor: 'transparent',
									width: "30vh",
									objectFit: 'contain'
								}}
								src={!isEmpty(webinarSettings.image_logo)  
									? process.env.REACT_APP_S3_BUCKET + "/event_uploads/images/logos/" + webinarSettings.image_logo 
									: DEFAULT_OLERN_TRANSPARENT_LOGO}
								alt="Olern Logo"
							/>
							<TextView className="color-white font-size-xxxl font-weight-medium mb-2">
								{webinarSettings.title}
							</TextView>
							<div className="d-flex font-size-xm">
								<div className="me-2">
									<TextView variant="body1" className="text-white d-flex align-items-center">
										<AccessTimeIcon />&nbsp;{`${formatTimeToAmPm(webinarSettings.start_time)} Asia/Singapore Time`}
									</TextView>
								</div>
								<div className="ml-5">
									<TextView variant="body1" className="text-white d-flex align-items-center">
										<CalendarTodayIcon />&nbsp;{`${webinarSettings.scheduled_date}`}
									</TextView>
								</div>
							</div>
						</div>
						
						<div className="quiz-container ms-auto text-white px-3 me-5">
							<TextView variant="body1" className="text-white d-flex align-items-center">
								Listen to Music
							</TextView>
							<ButtonGroup>
								<IconButton onClick={handleMuteToggle} color="primary">
									{isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
								</IconButton>
								<IconButton onClick={handlePlayPause} color="primary">
									{isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
								</IconButton>
								<IconButton color="gray">
									<SkipNextIcon />
								</IconButton>
							</ButtonGroup>
							{
								!isEmpty(webinarSettings?.event_settings?.event_music) && (
									<audio 
										ref={audioRef} 
										src={process.env.REACT_APP_S3_BUCKET + "/event_uploads/audios/lobby_music/" + webinarSettings.event_settings.event_music} 
										loop 
									/>
								)
								
							}
						</div>

					</div>
					
				</div>
			</div>
		)
	)

}

export default WaitingArea;