import React, { createContext, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "../../helpers/Functions";
import { DEFAULT_OLERN_OPTION_ICON, LIBRARY_OVERVIEW_PAGE, LIBRARY_SETTINGS_PAGE, LIBRARY_TRAINING_OUTLINE_PAGE, LIBRARY_USERS_PAGE, ROLE_MENTOR, ROLE_STUDENT } from "../../models/Constants";
import TextView from "../partials/Typography";
import { Box, Drawer, List, ListItemText, Tooltip } from "@mui/material";
import { Image } from "react-bootstrap";
import ActionIconButton from "../partials/ActionIconButton";

const libraryDetailSidebarContext = createContext();

export function useLibraryDetailSidebarContext() {
	return useContext(libraryDetailSidebarContext);
}

const LibraryDetailSidebar = ({
	primaryOptions = [],
	navigationOptions = {},
	isOpen = false,
	setIsOpen = () => {},
	selectedPrimaryValue,
	uniqSelectedNavValue,
	selectedNavValue,
	selectedNavItemValue,

	onPrimaryOptionClick = () => {},
	onNavigationClick = () => {},
	onNavigationItemClick = () => {},
}) => {

	useEffect(() => {
		setIsOpen(!isEmpty(navigationOptions?.[selectedPrimaryValue]));
	}, [selectedPrimaryValue]);

	return (
		<Drawer
			variant="permanent"
			open={isOpen}
			sx={{
				width: isOpen ? 338 : 60,
				position: 'relative',
				transition: 'width 0.3s ease',
				'& .MuiDrawer-paper': {
					width: isOpen ? 338 : 60,
					overflowX: 'hidden',
					transition: 'width 0.3s ease',
				},
				zIndex: 0,
			}}
		>
			<Box style={{
				height: '100%',
				display: 'flex',
			}}>
				<div className="lrnx-sidebar-fixed">
					<div className="lrnx-sidebar-icons-container">
						<Link to="/">
							<Image className="lrnx-sidebar-logo-icon" src={DEFAULT_OLERN_OPTION_ICON} />
						</Link>
						<List className="lrnx-sidebar-icons">
							{primaryOptions?.map((item, i) => (
								<React.Fragment key={i}>
									<Tooltip
										placement="right"
										title={item.title}
										arrow
									>
										<ListItemText
											className={`lrnx-sidebar-icons-item ${item.value === selectedPrimaryValue && 'active'}`}
											onClick={() => {
												let value = item?.value;

												onPrimaryOptionClick(value);
											}}
										>
											{item.icon}
										</ListItemText>
									</Tooltip>
									{item.line_break && <Box className="main-sidebar-divider" />}
								</React.Fragment>
							))}
						</List>
					</div>
				</div>
				<div className={`lrnx-sidebar-collapsible ${isOpen ? '' : 'close'}`}>
					<div className="lrnx-sidebar-collapsible-header">
						<div className="lrnx-sidebar-collapsible-header-title">
							<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M5 10L5.705 9.295L1.915 5.5H12V4.5H1.915L5.705 0.705L5 0L0 5L5 10Z" fill="white" />
							</svg>

							<TextView fontsize="xm" fontweight="bold">
								{primaryOptions?.find((primOpt) => primOpt.value === selectedPrimaryValue)?.title}
							</TextView>
						</div>
						<ActionIconButton
							customclasses="lrnx-sidebar-collapsible-header-collapse"
							label={"collapse"}
							onClick={() => {
								setIsOpen(!isOpen);
							}}
						/>
					</div>
					{navigationOptions?.[selectedPrimaryValue]?.map((cluster, i) => (
						<div className="lrnx-sidebar-collapsible-clusters" key={i}>
							{cluster?.items && (
								<>
									<TextView
										fontsize="primary"
										fontweight="semibold"
										customclasses={`lrnx-sidebar-collapsible-title ${cluster.interactive && 'interactive'} ${(cluster.value === uniqSelectedNavValue) && 'active'}`}
										onClick={() => {
											if (!cluster.interactive) {
												return;
											}

											onNavigationClick(cluster.value);
										}}
									>{cluster.title}</TextView>
									<Box className="lrnx-sidebar-collapsible-title-divider" />
									<List className="lrnx-sidebar-wide-options">
										{cluster?.items?.map((item, y) => (
											<ListItemText
												key={y}
												className={`lrnx-sidebar-wide-options-item library-details-sidebar ${selectedNavValue === cluster.value && selectedNavItemValue === item.value ? 'active' : ''} ${item.disabled && 'disabled'}`}
												onClick={() => {
													onNavigationItemClick(cluster?.value, item?.value);
												}}
											>
												<TextView fontsize="xm" fontweight="regular" customclasses="lrnx-sidebar-wide-options-item-text library-details-sidebar">
													{item.title}
												</TextView>
											</ListItemText>
										))}
									</List>
								</>
							)}
						</div>
					))}
				</div>
			</Box>
		</Drawer>
	);
}

export default LibraryDetailSidebar;